import React, { useState, useContext } from "react"
import styled from "styled-components"
import { Container } from "../global"
import intention from "../../images/btc-meditation.png"
import { Formik, Form, Field, ErrorMessage } from "formik"

import firebase from "gatsby-plugin-firebase"
import { AuthContext } from "../../context/auth"
import { navigate } from "gatsby"

const SignIn = () => {
  const { user, setUser } = useContext(AuthContext)

  const signIn = (values) => {
    console.log("values.email", values.email)
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then((userCredential) => {
        // Signed in
        setUser(userCredential.user)
        console.log("signed in")
        //window.location.href = "/dashboard"
        // ...
        navigate("/dashboard")
      })
      .catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message
      })
  }

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <center>
              <img src={intention} alt="intention" width="350px" />
            </center>
          </HeaderTextGroup>
          <div>
            <Formik
              initialValues={{ email: "", password: "" }}
              validate={(values) => {
                const errors = {}
                if (!values.email) {
                  errors.email = "Required"
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address"
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                signIn(values)
              }}
            >
              {({ isSubmitting }) => (
                <FormWrapper>
                  <h4>Sign in</h4>
                  <Form>
                    <label htmlFor="email">Email Address</label>
                    <Field type="email" name="email" style={{width:'100%'}}/>
                    <ErrorMessage name="email" component="div" />
                    <br />
                    <br />
                    <label htmlFor="password">Password</label>
                    <Field type="password" name="password" style={{width:'100%'}}/>
                    <ErrorMessage name="password" component="div" />
                    <br />
                    <br />
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      style={{
                        width: "300px",
                        height: "35px",
                        backgroundColor: "#FFBB60",
                        color: "#fff",
                        borderRadius: "3px",
                      }}
                    >
                      Sign in
                    </button>
                  </Form>
                </FormWrapper>
              )}
            </Formik>
          </div>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default SignIn

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    margin-top: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Input = styled.input`
  width: 300px;
  height: 35px;
  border: 1px solid #ccc;
  background-color: #fff;
`
